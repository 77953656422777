import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

const initialState = {
  newBlog: {},
  newsArticles: [],
  loading: false,
  blogDetails: null,
  publishingData: null,
  canComment: true,
  canAgree: true,
  publishingAsData: null,
  isArticleDeleted: false,
};

export const createBlog = createAsyncThunk('createBlog', async (body) => {
  const obj = {
    url: APIEndPoints.CreateBlog,
    method: 'POST',
    body: body,
    isFormData: true,
  };
  const response = await API(obj);
  return response;
});

export const listDraft = createAsyncThunk('listDraft', async (body) => {
  const obj = {
    url: APIEndPoints.listBlog,
  };
  return await API(obj);
});

export const getNewsArticles = createAsyncThunk(
  'getNewsArticles',
  async (payload) => {
    const obj = {
      url: APIEndPoints.GetNews(payload.pageNo, payload.limitPerPage),
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const listPublished = createAsyncThunk('listPublished', async (body) => {
  const obj = {
    url: APIEndPoints.listPublished,
  };
  return await API(obj);
});

export const listAllBlogs = createAsyncThunk(
  'listAllBlogs',
  async ({ pageParam = 1 }) => {
    const obj = {
      url: APIEndPoints.GetBlogs(pageParam, 20),
    };
    return await API(obj);
  }
);

export const publishBlog = createAsyncThunk(
  'publishBlog',
  async (query, fn) => {
    const obj = {
      url: APIEndPoints.publishBlog(query),
      method: 'PUT',
    };
    return await API(obj);
  }
);

export const deleteBlog = createAsyncThunk('deleteBlog', async (query) => {
  const obj = {
    url: APIEndPoints.PostDelete(query),
    method: 'DELETE',
  };
  return await API(obj);
});

export const editBlog = createAsyncThunk('editBlog', async (body) => {
  const obj = {
    url: APIEndPoints.editBlog(body.id),
    body: body.formdata,
    isFormData: true,
    method: 'PUT',
  };
  return await API(obj);
});
export const blogDetailsSlug = createAsyncThunk(
  'blogDetailsSlug',
  async (slug) => {
    const obj = {
      url: `${APIEndPoints.GetBlogsDetails}${slug}/detail`,
      isNoToken: true,
    };
    return await API(obj);
  }
);

export const bookMarkPost = createAsyncThunk(
  'bookMarkPost',
  async (postId) => {
    const obj = {
      url: `${APIEndPoints.GetBlogsDetails}${postId}/bookmark`,
      method: 'POST',
    };
    return await API(obj);
  }
);

export const removeBookMark = createAsyncThunk(
  'removeBookMark',
  async (postId) => {
    const obj = {
      url: `${APIEndPoints.GetBlogsDetails}${postId}/bookmark`,
      method: 'DELETE',
    };
    return await API(obj);
  }
);

export const markReadPost = createAsyncThunk('markReadPost', async (postId) => {
  const obj = {
    url: `${APIEndPoints.GetBlogsDetails}${postId}/read`,
    method: 'PUT',
  };
  return await API(obj);
});

export const setPublishingToData = createAction('setPublishingToData');
export const setPublishingAsData = createAction('setPublishingAsData');
export const setIsComment = createAction('setIsComment');
export const setIsAgree = createAction('setIsAgree');

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.newBlog.post_id = action.payload;
    },
    setTitle: (state, action) => {
      state.newBlog.title = action.payload;
    },
    setDescription: (state, action) => {
      state.newBlog.description = action.payload;
    },
    setCoverImage: (state, action) => {
      state.newBlog.cover_image = action.payload;
    },
    setBlogType: (state, action) => {
      state.newBlog.blogType = action.payload;
    },
    clearCreateBlog: (state, action) => {
      state.newBlog = {};
    },
  },
  extraReducers: {
    [getNewsArticles.fulfilled]: (state, action) => {
      state.newsArticles = action?.payload;
    },
    [blogDetailsSlug.pending]: (state, action) => {
      state.loading = true;
    },
    [blogDetailsSlug.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogDetails = action.payload;
    },
    [setPublishingToData]: (state, action) => {
      state.publishingToData = action.payload;
    },
    [deleteBlog.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.isArticleDeleted = true;
    },
    [setPublishingAsData]: (state, action) => {
      state.publishingAsData = action.payload;
    },
    [setIsComment]: (state, action) => {
      state.canComment = action.payload
    },
    [setIsAgree]: (state, action) => {
      state.canAgree = action.payload
    }
  },
});
export const {
  clearCreateBlog,
  setBlogType,
  setCoverImage,
  setTitle,
  setDescription,
  setId,
} = blogSlice.actions;
export default blogSlice.reducer;
