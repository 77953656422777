/* eslint-disable @nx/enforce-module-boundaries */
import {
  DropNotifIcon,
  MenuThreeDots,
  NotifDiskIcon,
  NotifHandShakeIcon,
  NotifMeetingIcon,
  NotifTeamIcon,
  NotifTestimonialIcon,
  SimpleTickIcon,
  NotifAcceptHandShakeIcon,
  DefaultNotificationIcon,
  PostCommentIcon,
  PostSharedIcon
} from '@mybridge/icons/NotificationIcons';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
  Image
} from '@mybridge/ui';
import moment from 'moment';
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateNotification,
  getNotificationList,
} from 'v4/store/actions/notification.actions';
import styles from './notification.module.scss';
import { Menu, MenuButton, MenuList, MenuItem } from '@mybridge/ui/menu';
import { useRouter } from 'next/router';
import { getEventDetail } from 'v4/store/actions/meeting.actions';
import { getTeamDetails } from 'v4/store/actions/teamCommunity.actions';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

const NotificationCard = (props) => {
  const { details } = props;
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const date = moment.utc(details?.created_at).local();
  // const byUser = details ? JSON.parse(details?.data?.created_by) : null;
  const byUser = details ? details?.data?.created_by : null;
  // const { messages } = useSelector((state) => state.notification);
  // const [inboxMessage, setInboxMessage] = useState(null);
  const { updatedStatus } = useSelector((state) => state.notifications);
  const { allDegreeConnections } = useSelector((state) => state.connection);
  const { matrixClient } = useContext(MatrixContext);

  const handleReadMessage = (id) => {
    const body = { status: details?.status === 2 ? 1 : 2, id: id };
    dispatch(updateNotification(body));
  };

  const handleRemoveMessage = (id) => {
    const body = { status: 3, id: id };
    dispatch(updateNotification(body));
  };

  const notificationIcon = (title) => {
    if (title === 'handshake_request') {
      return <NotifHandShakeIcon />;
    } else if (title === 'handshake_request_accepted') {
      return <NotifAcceptHandShakeIcon />;
    } else if (title === 'meeting_invite' || title === 'attendees_update' || title === 'meeting_accepted' || title === 'meeting_update' || title === 'meeting_canceled' || title === 'event_invite' || title === 'meeting_tentative' || title === 'meeting_proposed' || title === 'meeting_declined') {
      return <NotifMeetingIcon />;
    } else if (title === 'team_update' || title === 'team_invite' || title === 'team_member_joined' || title === 'team_member_removed' || title === 'team_chat_approval_pending' || title === 'team_chat_accept' || title === 'team_chat_declined' || title === 'team_user_disk_write_access' || title === 'team_become_organizer' || title === 'team_member_removed' || title === 'team_request' || title === 'team_invite_accepted' || title === 'disk_subscriber_request' || title === 'disk_subscriber_changed') {
      return <NotifTeamIcon />;
    } else if (title === 'post_liked' || title === 'post_disliked'|| title === 'post_comment_disliked' || title === 'post_comment_liked') {
      return <NotifHandShakeIcon color={(title === 'post_liked' || title === 'post_comment_liked') ? '#037F29' : '#E41C19'} />;
    } else if (title === 'post_comment' || title === 'post_reply' || title === 'post_comment_replied') {
      return <PostCommentIcon />;
    } else if (title === 'post_shared' || title === 'post_tagged') {
      return <PostSharedIcon />;
    } else if (title === 'testimonial_given' || title === 'testimonial_asked') {
      return <NotifTestimonialIcon />;
    } else if (title === 'disk_shared') {
      return <NotifDiskIcon />;
    } else {
      return <DefaultNotificationIcon />;
    }
  };

  const handleCardClick = async (detail) => {
    console.log(detail.data.type,"detail.data.type");
    if (
      (detail.data.type === 'post_shared' ||
        detail.data.type === 'post_liked' ||
        detail.data.type === 'post_comment' || 
        detail.data.type === 'post_disliked' ||
        detail.data.type === 'post_comment_replied' ||
        detail.data.type === 'post_comment_liked' ||
        detail.data.type === 'post_comment_disliked' ||
        detail.data.type === 'post_tagged'
      ) &&
      detail?.data?.id
    ) {
      router.push(`/?post=${detail?.data?.id}`);
    } else if (detail.data.type === 'profile_update') {
      router.push(`/profile/me`);
    } else if (detail.data.type === 'testimonial_asked' || detail.data.type === 'testimonial_given') {
      router.push(`/profile/testimonials`);
    } else if (detail.data.type === 'handshake_request') {
      router.push(`/profile/network/manage`);
    } else if (detail.data.type === 'handshake_request_accepted') {
      const matchingConnection = allDegreeConnections.find(
        connection => connection?.id === details?.data?.created_by?.id
      );
      router.push(`/p/${matchingConnection?.slug}`);
    } else if (detail.data.type === 'disk_shared') {
      router.push(`/profile/disk`);
    }else if (detail.data.type === 'disk_subscriber_request') {
      router.push(`/profile/disk`);
    }else if (detail.data.type === 'event_invite' && detail?.data?.id) {
      router.push(`/myevents/details/${detail?.data?.id}`);
    } else if ((detail.data.type === 'team_invite' || detail.data.type === 'team_update' ||  detail.data.type === 'team_member_joined' || detail.data.type === 'team_member_removed' || detail.data.type === 'team_chat_approval_pending' || detail.data.type === 'team_chat_accept' || detail.data.type === 'team_chat_declined' || detail.data.type === 'team_user_disk_write_access' || detail.data.type === 'team_become_organizer' || detail.data.type === 'team_member_removed' || detail.data.type === 'team_request' || details.data.type === 'team_invite_accepted' || details.data.type === 'disk_subscriber_request' || details.data.type === 'disk_subscriber_changed') && detail?.data?.slug) {
      router.push(`/teams/${detail?.data?.slug}`);
    } else if ((detail.data.type === 'meeting_invite' || detail.data.type === 'meeting_accepted' || detail.data.type === 'attendees_update' || detail.data.type === 'meeting_update') && detail?.data?.id|| detail.data.type === 'meeting_tentative'|| detail.data.type === 'meeting_proposed' || detail.data.type === 'meeting_declined'|| detail.data.type === 'meeting_canceled') {
      await dispatch(getEventDetail(detail?.data?.id)).then((res) => {
        if (res) {
          router.push(`/profile/calendar/viewMeeting`);
        } else {
          router.push(`/notification`);
        }
      });
    } else {
      router.push(`/notification`);
    }
    const body = { status: 2, id: detail?.id };
    dispatch(updateNotification(body));
    if (props.onClose) {
      props.onClose(true);
    }
  };

  const handleAction = async (url, id, data) => {
    await fetch(url, {
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // method:'PUT'
    });
    let body = {}
    if(data?.type === "handshake_request"){
      body = { status: 3, id: id };
    } else {
      body = { status: 2, id: id };
    }
    
    dispatch(updateNotification(body));
    if(data?.chat_room_id){
      await matrixClient.joinRoom(data?.chat_room_id).then((res) => console.log("room invite accepted", res));
    }
    // const response = await axiosInstance.patch(url, '', headers);
  };
  // console.log(details);
  return (
    <Box className={styles.notificationCard}>
      <Box className={styles.notificationCardInner}>
        <Box
          className={styles.notificationCardProfile}
          onClick={() => handleCardClick(details)}
          cursor="pointer"
        >
          <Avatar
            size="md"
            name={`${byUser?.first_name} ${byUser?.last_name}`}
            src={byUser?.profile_pic}
          />
          <Box className={styles.notificationCardIcon} border="2px solid white" borderRadius="50%">
            {details && notificationIcon(details?.data?.type)}
          </Box>
        </Box>
        <Box className={styles.notificationCardDetails}>
          <Box as="span" onClick={() => handleCardClick(details)}>
            <Text
              className={styles.notificationCardTitle}
              dangerouslySetInnerHTML={{
                __html: details?.data?.title
                  ? details?.data?.title
                  : details?.data?.sender_name,
              }}
            ></Text>
            <Text
              className={styles.notificationCardPreview}
              dangerouslySetInnerHTML={{
                __html: details?.data?.body
                  ? details?.data?.body
                  : details?.data?.msg,
              }}
            ></Text>
          </Box>
          {/* {details?.data?.accept_url || details?.data?.reject_url ? (
            <ul className={styles.notificationActions}>
              {details.data.accept_url && (
                <li>
                  <a
                    // onClick={() =>
                    //   handleAction(details.data.accept_url, details.id, details.data)
                    // }
                    onClick={() => handleCardClick(details)}
                  >
                    Accept
                  </a>
                </li>
              )}
              {details.data.reject_url && (
                <li>
                  <a
                    // onClick={() =>
                    //   handleAction(details.data.accept_url, details.id)
                    // }
                    onClick={() => handleCardClick(details)}
                  >
                    Reject
                  </a>
                </li>
              )}
              {details.data.propose_new_time_url && (
                <li>
                  <a
                    // onClick={() =>
                    //   handleAction(
                    //     details.data.propose_new_time_url,
                    //     details.id
                    //   )
                    // }
                    onClick={() => handleCardClick(details)}
                  >
                    Propose new time
                  </a>
                </li>
              )}
            </ul>
          ) : (
            <></>
          )} */}

          <Box as="span" className={styles.notificationCardTime}>
            {date.fromNow()}
          </Box>
        </Box>
        <Box className={styles.notificationCardActions} marginRight= 'auto'>
          {details?.status === 1 ? (
            <div className={styles.notificationCardStatus}></div>
          ) : (
            <React.Fragment>&nbsp;</React.Fragment>
          )}
          <Menu>
            <MenuButton
              as={IconButton}
              variant="transparent"
              p={0}
              w="12px"
              h="10px"
              justifyContent="center"
              icon={<MenuThreeDots width={11} height={4} />}
            />
            <MenuList>
              <MenuItem
                onClick={() => handleReadMessage(details.id)}
                variant="transparent"
              >
                
                <SimpleTickIcon /> 
                <Text ms={3}>
                  {details.status === 2 
                    ? ("Mark as unread") 
                    : (t('notification.MarkAsRead') || "Mark as read")}
                </Text>
              </MenuItem>
              <MenuItem
                onClick={() => handleRemoveMessage(details.id)}
                variant="transparent"
              >
                <Image src="/images/removeNotifIcon.svg" width="22px" height="22px" alt="Remove" />
                <Text ms={3}>{t('notification.removeNotification') || "Remove this notification"}</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationCard;
