/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  Card,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { MyNetworkContext } from 'v4/components/network/context';
import { SuggestedUserCard } from 'v4/components/user-card/suggested';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import { Input, InputGroup, InputLeftElement, InputRightElement, IconButton } from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';



export const MyNetworkSuggestionsListing = forwardRef(
  ({ fromOnboarding=false,limit = fromOnboarding?7:2, ...props }, ref) => {
    const {
      suggestions,
      refetchSuggestions,
      suggestionsIsFetching,
      suggestionsIsLoading,
    } = useContext(MyNetworkContext);
    const [showAll, setShowAll] = useState(false);
    const [displayLimits, setDisplayLimits] = useState({});
    const { pathname,push } = useRouter();
    const { t } = useTranslator();

    const [searchQuery, setSearchQuery] = useState("");
    const [initialResults, setInitialResults] = useState({ degree: [], follow: [], industry: [] });
    const [results, setResults] = useState({ degree: [], follow: [], industry: [] });
  
    // Set initial results when the component mounts
    useEffect(() => {
      setInitialResults({
        degree: suggestions?.degree,
        follow: suggestions?.follow,
        industry: suggestions?.industry
      });
      setResults({
        degree: suggestions?.degree,
        follow: suggestions?.follow,
        industry: suggestions?.industry
      });
    }, [suggestions?.degree, suggestions?.follow, suggestions?.industry]);
  
  
    const handleSearch = (e) => {

      const searchTerm = e.target.value.toLowerCase();
      setSearchQuery(searchTerm);
      if (searchTerm === "") {
        setResults(initialResults);
      } else {
        const filteredDegree = initialResults?.degree.filter((item) =>
              ["first_name", "last_name", "display_name", "position", "location"]
                .some((key) => item[key]?.toLowerCase().includes(searchTerm))
            );
        
        const filteredFollow = initialResults?.follow.filter((item) =>
          ["first_name", "last_name", "display_name", "position", "location"]
            .some((key) => item[key]?.toLowerCase().includes(searchTerm))
        );


        const industryFollow = initialResults?.industry.filter((item) =>
          ["first_name", "last_name", "display_name", "position", "location"]
            .some((key) => item[key]?.toLowerCase().includes(searchTerm))
        );
        
        
        setResults({ degree: filteredDegree, follow: filteredFollow, industry: industryFollow });
      }
    };

    const handleClear = () =>{
      setSearchQuery("");
      setResults({  degree: suggestions?.degree,
        follow: suggestions?.follow,
        industry: suggestions?.industry })

    }
  


    useEffect(() => {
      refetchSuggestions?.();
    }, []);

    const isNetworkPage = pathname === '/profile/network';
   const renderCategory = (title, items, key, maxLimit, isFollowCategory = false) => {
    
   const currentLimit = displayLimits[key] || maxLimit;

   const displayedItems = Array.isArray(items) ? items.slice(0, currentLimit) : []
 
  const handleSeeMore = () => {
    setDisplayLimits((prev) => ({
      ...prev,
      [key]: Math.min((prev[key] || maxLimit) + 7, items.length),
    }));
  };

  const handleShowAll = () => {
    setShowAll(true);
      console.log(key)
      setDisplayLimits((prev) => ({
        ...prev,
        [key]: items.length,
      }));
    
  };


 
      
      if (!items?.length) return null;

      return (
        <Box p={[0,4]} pt={[0, 4, 4]} px={isMobile? 3 : 0}>
          <HStack w="90%" justifyContent="space-between">
            <Heading
              fontSize="20px"
              fontWeight="400"
              color="brandPrimary.500"
              mb={isMobile && isNetworkPage ? 0 : 3}
            >
              {title}
            </Heading>
           
      
            {!fromOnboarding && items.length !== displayedItems.length  && !(isMobile && isNetworkPage) && (
              <Button
                variant="transparent"
                onClick={() => handleShowAll(key,items)} 
                color="#868585"
                fontWeight="500"
              >
                {t('post.seeAll') || "See All"}
              </Button>
            )}
            
          </HStack>
          <Wrap spacing={3}>
            {[...displayedItems].map((pi, piIndex) => (
              <WrapItem
                maxW={['49%', '30%', '190px']}
                flexGrow={['1', '1', 0]}
                flexShrink={['1', '1', 0]}
                flex="auto"
                key={piIndex}
                mb="10px"
              > 
                <SuggestedUserCard fromOnboarding={fromOnboarding} user={pi} highlightFollow={isFollowCategory} />
              </WrapItem>
            ))}
{fromOnboarding && items.length !== displayedItems.length && !(isMobile && isNetworkPage) && (
              <Button
                variant="transparent"
                alignItems={"start"}
                onClick={() => handleSeeMore(key,items)} 
                // color="#868585"
                fontWeight="500"
                color="brandPrimary.500"
                textDecoration="underline"
              >
                {t('profileNetwork.seeMore') || "See More"}
              </Button>
            )}
          </Wrap>
          
        </Box>
      );
    };

    return (
      <Stack spacing={3}>
        {suggestionsIsFetching || suggestionsIsLoading ? (
          <HStack justifyContent="center">
            <Spinner />
          </HStack>
        ) : isMobile && isNetworkPage ? (
          <>
            {renderCategory(
              '',
              [...(suggestions?.role ?? []), ...(suggestions?.industry ?? [])],
              'role_and_industry',
              limit
            )}
          </>
        ) : (
          <>
 {fromOnboarding&&(         <Box marginTop="10px" marginBottom="-18px" width="100%" display="flex" justifyContent="center">
          {/* <Box width = "100%" maxWidth="600px">
           <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        height="48px"
        placeholder="Search for someone specific to connect with..."
        value={searchQuery}
        onChange={handleSearch}
        variant="outline"
        focusBorderColor="#D9D9D9"
        borderRadius={"30px"}
      />
      {searchQuery && (
        <InputRightElement>
          <IconButton
            size="sm"
            icon={<CloseIcon />}
            onClick={handleClear}
            sx={{
              bgColor:'transparent',
              ":hover":{
                bgColor:'transparent'
              }
            }}
            aria-label="Clear search"
          />
        </InputRightElement>
      )}
          </InputGroup>
          </Box> */}
         </Box> )}
            {renderCategory(
              `${t('profileNetwork.peopleYouMayKnowFrom') || "People you may know from"} ${
                suggestions?.university?.[0]?.educations?.[0]?.name?.name ??
                (t('profileNetwork.similarUniversity') || 'similar university')
              }`,
              suggestions?.university,
              'university',
              limit
            )}
            {renderCategory(
              t('profileNetwork.peopleYouMayKnowBasedOnLocation') || 'People you may know based on your Location',
              suggestions?.location,
              'location',
              limit
            )}
            {/* {renderCategory(
              fromOnboarding ? t('profileNetwork.trendingPeopleToFollow')||'Trending people to follow' : t('profileNetwork.trendingPeopleInYourNetworkToFollow')||'Trending people in your network to follow',
              //results?.follow,
              [...(results?.follow ?? [])],
              'follow',
              limit
            )} */}
            {renderCategory(
              fromOnboarding ? t('profileNetwork.trendingPeopleToFollow')||'Trending people to follow' : t('profileNetwork.trendingPeopleInYourNetworkToFollow')||'Trending people in your network to follow',
              [...(results?.follow ?? [])],
              'follow',
              limit,
              true // Add a new parameter to indicate these are trending people to follow
            )}
            {renderCategory(
              t('profileNetwork.peopleYouMayKnowBasedOnDegrees')||'People you may know based on your Degrees',
              //results?.degree,
              [...(results?.degree ?? [])],
              'degree',
              limit
            )}
            {renderCategory(
              t('profileNetwork.peopleYouMayKnowWithSimilarRolesAndIndustry')||'People you may know with similar roles and industry',
              //[...(suggestions?.role ?? []), ...(results?.industry ?? [])],
              [...(suggestions?.role ?? []), ...(results?.industry ?? [])],
              'role_and_industry',
              limit
            )}
            {!suggestions?.university?.length &&
            !results?.follow?.length &&
            !results?.degree?.length &&
            !suggestions?.role?.length &&
            !results?.industry?.length &&
            !suggestions?.location?.length ? (
              <HStack justifyContent="center">
                <Text mt={16}>{t('profileNetwork.noSuggestionsForNow') || "No suggestions for now! Please come back later"}...</Text>
              </HStack>
            ) : null}
          </>
        )}
      </Stack>
    );
  }
);
