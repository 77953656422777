import React, { useEffect, useState } from 'react';
import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en';
import { parsePhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';
import { useTranslator } from '../custom-hooks/useTranslation';
// import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers';

export function PhoneNumberInput({
  children,
  value,
  defaultValue,
  onChange,
  inputProps,
  selectProps,
  defaultDialCode,
  onDailCodeChange,
  onValidationChange,
  ...props
}) {
  const separate = (val) => {
    const [dialCode_, phone_] = ((val ?? '') + '').split('-') ?? [];
    return {
      dialCode: dialCode_?.length ? dialCode_ : undefined,
      phone: phone_,
    };
  };

  const join = (dialCode_, phone_) => {
    return [dialCode_ ?? '', phone_ ?? ''].join('-');
  };
  const { t } = useTranslator();
  const [phone, setPhone] = useState(value ?? defaultValue);
  const [country, setCountry] = useState('US');
  const [dialCode, setDialCode] = useState(
    separate(defaultValue)?.dialCode ?? defaultDialCode
  );
  const [errorMessage, setErrorMessage] = useState(''); // State for validation message

  useEffect(() => {
    if (defaultDialCode && value) {
      const phoneNumber = parsePhoneNumber(`${defaultDialCode}${value}`);
      setCountry(phoneNumber?.country);
      setPhone(phoneNumber?.nationalNumber);
    }
  }, [value, defaultDialCode]);

  const onPhoneChange = (e) => {
    const phone_ = e?.target?.value;

    // Validate that the phone number does not exceed 15 digits
    if (phone_ && phone_.length > 15) {
      setErrorMessage('Phone number cannot exceed 15 digits'); // Set validation message
      onValidationChange?.(false); // Notify parent of invalid state
      return; // Prevent updating the state if the input exceeds 15 digits
    }

    setErrorMessage(''); // Clear the validation message if input is valid
    onValidationChange?.(true); // Notify parent of valid state
    setPhone(phone_);
    if (phone_ && phone_.length) {
      onChange?.(phone_);
      if (country) {
        onDailCodeChange?.(getCountryCallingCode(country));
      }
    } else {
      onChange?.('');
      onDailCodeChange?.('');
    }
  };

  useEffect(() => {
    if (country) {
      onDailCodeChange?.(getCountryCallingCode(country));
    }
  }, [country]);

  const CountrySelect = ({ value, onChange, labels, ...rest }) => (
    <Select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
      border="none"
      bg="transparent"
    >
      <option value="">Country</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
      ))}
    </Select>
  );


  return (
    <div>
      <InputGroup {...props}>
        <InputLeftElement minW="200px">
          <CountrySelect labels={en} value={country} onChange={setCountry} />
        </InputLeftElement>
        <Input
          type="number"
          pl={'220px'}
          {...(inputProps ?? {})}
          value={phone}
          onChange={onPhoneChange}
          placeholder={t('pages.mobileNumber') || "Mobile Number"}
        >
          {children}
        </Input>
      </InputGroup>
      {errorMessage && (
        <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
}
