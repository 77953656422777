import { Button, CloseButton, ModalFooter, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, DeleteIcon2 } from '@mybridge/icons';
import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
} from '@mybridge/ui';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import axios from 'axios';
import { useRouter } from 'next/router';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { GlobalContext } from 'v4/snippets/global/context';
import { TeamsContext } from 'v4/snippets/teams/context';
import { getUserRoomList, removeGroupImage } from 'v4/store/actions/global.data.actions';
import { removeTeamImage } from 'v4/store/actions/teamCommunity.actions';
import {
  getMyActivity,
  getUserProfileInfo,
} from 'v4/store/actions/user-profile.actions';
import { loadDictionary, setLanguage } from 'v4/store/slices/translationSlice';
export const ImagePopup = forwardRef(({ src, onClose, ...props }, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { loggedIn } = useSelector((state) => state.user);
  const { teamDetails, createOrUpdateTeam, setChatTeamSlug, isOrganizer } =
    useContext(TeamsContext);
  const {  pathname } = useRouter();
  const isProfilePage = pathname.includes('/p/') || pathname.includes('/t/');
  const {
    selectedChat,
    setSelectedChat,
    connectionList,
    setConnectionList,
    roomId,
    setRoomId,
    newChatDisc,
  } = useContext(MatrixContext);
  const { imageType } = useContext(GlobalContext);
  console.log(selectedChat, 'selectedChatselectedChatfdgdh');
  const SOCKET_SERVER_URL = 'wss://api.bybk.org';
  const { userProfileInfo } = useSelector((state) => state.userProfile);

  const fetchRoomList = async (searchQuery = 'fromUpdateImage') => {
    dispatch(getUserRoomList(searchQuery))
      .then((res) => {
        const rooms = res?.payload?.rooms || [];
        setConnectionList(res?.payload?.rooms || []); // Ensure rooms default to an empty array if undefined

        const totalNotifications = rooms?.reduce(
          (sum, room) => sum + (room?.notification_count || 0),
          0
        );

        console.log(selectedChat, 'selectedChatselectedChat');

        if (searchQuery === 'fromUpdateImage') {
          let openedChat = rooms?.filter(
            (room) => room?.room_id === selectedChat?.room_id
          );
          setSelectedChat(openedChat[0]);
        }
      })
      .finally(() => {});
  };
  const socketRef = useRef(null);

  useEffect(() => {
    socketRef.current = io(SOCKET_SERVER_URL);
    return () => socketRef.current?.disconnect();
  }, []);

  const handleRemoveClick = () => {
    setShowConfirmation(true);
  };

  const handleCancelRemove = () => {
    setShowConfirmation(false);
  };

  const handleRemove = async () => {
    setShowConfirmation(false);
    const formData = new FormData();
    if (imageType === 'team-banner') {
      setIsLoading(true);
      const payload = {
        id: teamDetails?.id,
        image_type: 'banner_image'
      };
      dispatch(removeTeamImage(payload))
        .then((res) => {
          setChatTeamSlug(teamDetails?.slug);
          setIsSuccess(true);
          setIsLoading(false);
          if (onClose) {
            onClose();
          }
        });
      } else if (imageType === 'team-profile') {
        setIsLoading(true);
        const payload = {
          id: teamDetails?.id,
          image_type: 'image_logo'
        };
        dispatch(removeTeamImage(payload))
          .then((res) => {
            setChatTeamSlug(teamDetails?.slug);
            setIsSuccess(true);
            setIsLoading(false);
            if (onClose) {
              onClose();
            }
          });
      } else if (imageType === 'profile-banner') {
        setIsLoading(true);
        formData.append('profile_banner_pic', new Blob(), '');
        axios
          .put('https://api.bybk.org/api/v1/user/profile/info/', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access')}`,
            },
          })
          .then((response) => {
            setIsSuccess(true);
            setIsLoading(false);
            if (onClose) {
              onClose();
            }
          })
          .catch((error) => {
            console.error(
              'Error removing photo:',
              error?.response?.data || error?.message
            );
          });
      } else if (imageType === 'group-profile') {

     dispatch(removeGroupImage(selectedChat?.room_id)).then((res)=>{
      // Emit socket event for group avatar update
      socketRef?.current?.emit('chat_avatar_updated', {
        room_id: selectedChat?.room_id,
        user_id: userProfileInfo?.matrix_user_id,
        type: 'remove'
      });
      fetchRoomList()
      if (onClose) {
        onClose();
      }
     })
      console.log('remove group profile');
    } else {
      setIsLoading(true);
      formData.append('profile_pic', new Blob(), '');
      axios
        .put('https://api.bybk.org/api/v1/user/profile/info/', formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        })
        .then((response) => {
          setIsSuccess(true);
          setIsLoading(false);
          if (onClose) {
            onClose();
          }
        })
        .catch((error) => {
          console.error(
            'Error removing photo:',
            error?.response?.data || error?.message
          );
        });
    }
  };
  const closeSuccessModal = () => {
    setIsSuccess(false);
  };

  useEffect(() => {
    // dispatch(getUserProfileInfo())
    // .then((resp) => {
    //   const status_code = resp?.payload?.status_code ?? {};
    //   // console.log('status_code', status_code > 400);
    //   if (status_code > 400) {
    //     localStorage?.clear();
    //   } else {
    //     dispatch(loadDictionary(resp?.payload?.lang))
    //     dispatch(setLanguage(resp?.payload?.lang));
    //   }
    // })
    // // dispatch(getMyActivity());
    // dispatch(getMyActivity({ isNoToken: !loggedIn }));
    if (loggedIn) {
      // User is logged in, get language from profile
      dispatch(getUserProfileInfo())
        .then((resp) => {
          const status_code = resp?.payload?.status_code ?? {};
          if (status_code > 400) {
            // Handle error case
            localStorage?.clear();
          } else {
            // Set language from user profile
            dispatch(loadDictionary(resp?.payload?.lang))
            dispatch(setLanguage(resp?.payload?.lang));
          }
        });
    } else {
      // User is logged out, get language from localStorage
      const savedLang = localStorage.getItem('userLanguage') || 'en';
      dispatch(loadDictionary(savedLang));
      dispatch(setLanguage(savedLang));
    }
  }, [isSuccess, dispatch, loggedIn]);

  useEffect(() => {
        const handleAvatarUpdate = (data) => {
            if (data.room_id === roomId) {
                fetchRoomList();
            }
        };
    
        socketRef.current?.on('chat_avatar_updated', handleAvatarUpdate);
        
        return () => {
            socketRef.current?.off('chat_avatar_updated', handleAvatarUpdate);
        };
  }, [roomId]);

  return (
    <Portal>
      <Modal onClose={onClose} size="full" {...props}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>
        </ModalHeader> */}
          <ModalBody
            bgColor="blackAlpha.900"
            overflowY="auto"
            as={HStack}
            justifyContent="center"
            pos="relative"
          >
            <HStack
              pos="absolute"
              justifyContent="space-between"
              zIndex={2}
              left={0}
              right={0}
              top={0}
            >
              <Box display="flex" alignItems="center" gap="15px" p={2}>
                <Image src="/images/logoMb.png" w="8" h="8" />
              </Box>
              {isOrganizer && loggedIn && (
                isLoading ? (
                  <Spinner size="lg" mr={50} color="#fff" />
                ) : (
                  <Button
                    title={t('myMessages.removeProfilePic') || "Remove Profile Pic"}
                    padding={0}
                    variant="none"
                    leftIcon={<DeleteIcon2 color="white" />}
                    border={'transparent'}
                    color={'white'}
                    // onClick={handleRemove}
                    onClick={handleRemoveClick}
                    mr={50}
                  >
                    {t('myMessages.removePhoto') || "Remove Photo"}
                  </Button>
                )
              )}
              <ModalCloseButton color="#fff" />
            </HStack>
            <Image
              pos="relative"
              zIndex={1}
              bgColor="white"
              src={src}
              maxW="100%"
              maxH="99vh"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size="xl" isOpen={showConfirmation} onClose={handleCancelRemove}>
        <ModalOverlay 
          bg="rgba(0, 0, 0, 0.6)" 
          backdropFilter="blur(2px)"
        />
        <ModalContent>
          <ModalHeader>
            {t('imageUpload.deletePhoto') || "Delete Photo"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {t('otherModules.confirmRemove') || "Are you sure you want to remove"}?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleCancelRemove}>
              {t('buttons.cancel') || "Cancel"}
            </Button>
            <Button colorScheme="red" onClick={handleRemove}>
              {t('profile.remove') || "Remove"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isSuccess && (
        <Portal>
          <Modal isOpen={isSuccess}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                display="flex"
                justifyContent="space-between"
                alignItem="center"
              >
                {t('myMessages.deleted') || "Deleted"}
                <CloseButton
                  variant={'none'}
                  padding={0}
                  border={0}
                  onClick={closeSuccessModal}
                />
              </ModalHeader>
              <ModalBody>
                {t('myMessages.yourPictureRemoved') || `Your picture has been successfully removed`}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </Portal>
  );
});
