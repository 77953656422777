/* eslint-disable @nx/enforce-module-boundaries */
import { DegreeConnectionsIcon } from '@mybridge/icons/DegreeConnectionsIcon';
import { FollowersIcon, UnfollowersIcon } from '@mybridge/icons/Followers';
import { MutualConnectionIcon } from '@mybridge/icons/MutualConnectionIcon';
import {
  Avatar,
  HStack,
  Link,
  Stack,
  Text,
  AvatarBadge,
  Divider,
  Card,
  AvatarGroup,
  Box,
} from '@mybridge/ui';
import { forwardRef, useMemo, useContext } from 'react';
import {
  formatCommaSeparated,
  formatLocation,
  getUserFullName,
  getUserProfilePic,
} from 'v4/lib/commons';
import { MyNetworkContext } from '../context';
import { useRouter } from 'next/router';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const MyNetworkUserCard = forwardRef(
  (
    {
      user = {},
      actions,
      children,
      showFollowers,
      avatarProps,
      divider = false,
      isSearchCard,
      ...props
    },
    ref
  ) => {
    const router = useRouter();
    const { t } = useTranslator();
    const name = getUserFullName(user);
    const profile_pic = getUserProfilePic(user);
    const {
      position,
      experiences,
      educations,
      slug,
      degree,
      degree_count,
      mutual_count,
      followers_count,
      matrix_presence,
      mutual_connections,
      mutual_connection_count,
      company,
    } = user ?? {};
    const networkCtx = useContext(MyNetworkContext);
    const {
      isFollowed,
      isFriend,
      followUser,
      handshakeUser,
      handshakeLoading,
      followLoading,
      isHandshaked,
      setSelectedUser,
    } = networkCtx ?? {};

    const degree_ = degree_count ?? degree;

    const location = useMemo(() => {
      let loc = formatLocation(user);
      return loc?.length ? loc : user?.location;
    }, [user]);

    const MATRIX_PRESENCE = {
      online: {
        bgColor: 'green.500',
        priority: 2,
      },
      busy: {
        bgColor: 'red.500',
        priority: 1,
      },
      offline: {
        bgColor: 'gray.500',
        priority: 0,
      },
    };

    return (
      <>
        {props?.isInvitations ? (
          <Card
            variant="thinBorderedCard"
            p={3}
            w={['100%','380px']}
            h={'177px'}
            border="1px solid #E4E6EB"
            borderRadius="20px"
          >
            <Stack
              direction={['column', 'row']}
              alignItems={['flex-start', 'center']}
              {...props}
            >
              <HStack flex={1} alignItems="flex-start">
                <Avatar
                  {...(slug?.length ? { as: Link, href: '/p/' + slug } : {})}
                  name={name}
                  size="lg"
                  {...(profile_pic?.length ? { src: profile_pic } : {})}
                  {...(avatarProps ?? {})}
                >
                  {/* {isFriend?.(user) && 
            <AvatarBadge bg={...(MATRIX_PRESENCE[matrix_presence]?.bgColor ?? {})} />
            } */}
                </Avatar>
                <Stack spacing={1} flex={1} pl={2}>
                  {name ? (
                    <HStack>
                      <Text
                        // mt={1}
                        fontWeight={isSearchCard ? '700' : 'medium'}
                        color="#5B5B5B"
                        {...(slug?.length
                          ? { as: Link, href: '/p/' + slug }
                          : {})}
                      >
                        {name}
                      </Text>
                      {followers_count && showFollowers ? (
                        <HStack>
                          <FollowersIcon width="20" height="20" />
                          <Text>{followers_count}</Text>
                        </HStack>
                      ) : (
                        //   <HStack >
                        //   <UnfollowersIcon width="20" height="20" />
                        //   <Text>{followers_count}</Text>
                        // </HStack>
                        ''
                      )}
                    </HStack>
                  ) : (
                    ''
                  )}
                  {experiences?.length ? (
                    <>
                      <Text fontSize="sm">
                        {formatCommaSeparated([experiences?.[0]?.title])}
                      </Text>
                      <Text fontSize="xs" color="#868585">
                        {formatCommaSeparated([experiences?.[0]?.company_name])}
                      </Text>
                    </>
                  ) : educations?.length ? (
                    <Text fontSize="sm">{educations?.[0]?.name}</Text>
                  ) : (
                    (
                      <Stack spacing={1} align="flex-start">
                        <Text fontSize="sm" lineHeight="1.2">
                          {position}
                        </Text>
                        <Text fontSize="xs" color="#868585" lineHeight="1.2">
                          {company}
                        </Text>
                      </Stack>
                    ) ?? ''
                  )}
                  {location ? (
                    <Text fontSize="sm" color="blackAlpha.500">
                      {location}
                    </Text>
                  ) : (
                    ''
                  )}

                  {mutual_connections?.length ? (
                    <>
                      <HStack
                        spacing={0}
                        alignItems="flex-start"
                        mt={1}
                        onClick={() =>
                          router.push(`/profile/network/${user.slug}`)
                        }
                        cursor="pointer"
                      >
                        <AvatarGroup color="blackAlpha.500" size="sm" max={3}>
                          {mutual_connections?.map?.((u, uInd) => (
                            <Avatar
                              as={Link}
                              href={'/p/' + u?.slug}
                              key={uInd}
                              width="18px"
                              height="18px"
                              src={getUserProfilePic(u)}
                              name={getUserFullName(u)}
                              border="1px solid #fff"
                            />
                          ))}
                        </AvatarGroup>
                        <Text fontSize="12px" color="#868585" ms={1}>
                          {mutual_connection_count > 3
                            ? `+${mutual_connection_count - 3}`
                            : ''}{' '}
                          {t('profileNetwork.sharedConnections') || "shared connections"}
                        </Text>
                      </HStack>
                    </>
                  ) : (
                    <Box minH="24px"></Box>
                  )}

                  {children}

                  <Stack
                    {...(props?.flexWrap ? { w: '100%' } : {})}
                    h="100%"
                    justifyContent="center"
                  >
                    {actions}
                  </Stack>
                </Stack>
              </HStack>
            </Stack>
          </Card>
        ) : (
          <>
            <HStack
              direction={['column', 'row']}
              alignItems="start"
              {...props}
              mt={2}
            >
              <Avatar
                width="50px"
                height="50px"
                {...(slug?.length ? { as: Link, href: '/p/' + slug } : {})}
                name={name}
                size="lg"
                {...(profile_pic?.length ? { src: profile_pic } : {})}
                {...(avatarProps ?? {})}
              >
                {/* {isFriend?.(user) && 
            <AvatarBadge bg={...(MATRIX_PRESENCE[matrix_presence]?.bgColor ?? {})} />} */}
              </Avatar>
              <HStack spacing={3} alignItems="flex-start" flexDirection={"row"} mt={0} flex={1}>
                <Stack minH={"60px"} spacing={0} flex={1} pl={2}>
                  {name ? (
                    <HStack>
                      <Text
                        fontWeight={isSearchCard ? '500' : 'medium'}
                        color={isSearchCard ? '#3D5A80' : '#000000'}
                        {...(slug?.length
                          ? { as: Link, href: '/p/' + slug }
                          : {})}
                        fontSize={'16px'}
                        lineHeight="18px"
                      >
                        {name}
                      </Text>
                      {followers_count && showFollowers ? (
                        <HStack>
                          <FollowersIcon width="20" height="20" />
                          <Text>{followers_count}</Text>
                        </HStack>
                      ) : (
                        // <HStack>
                        //   <UnfollowersIcon width="20" height="20" />
                        //   <Text>{followers_count}</Text>
                        // </HStack>
                        ''
                      )}
                    </HStack>
                  ) : (
                    ''
                  )}
                  {experiences?.length ? (
                    <Text maxW="200px" fontSize="14px">
                      {/* {formatCommaSeparated([
                      experiences?.[0]?.title,
                      experiences?.[0]?.company_name,
                    ])} */}
                      {experiences?.[0]?.title} at{' '}
                      <Box as="span" fontWeight="600">
                        {experiences?.[0]?.company_name}
                      </Box>
                    </Text>
                  ) : educations?.length ? (
                    <Text fontSize="14px">{educations?.[0]?.name?.length>30?educations?.[0]?.name?.slice(0,30)+"...":educations?.[0]?.name}</Text>
                  ) : (
                    <Text fontSize="14px">{position}</Text> ?? ''
                  )}
                  {location ? (
                    <Text mt={2} fontSize="14px" color="#9F9F9F">
                      {location}
                    </Text>
                  ) : (
                    ''
                  )}
                  {mutual_connections?.length ? (
                    <>
                      <HStack
                        spacing={0}
                        alignItems="flex-start"
                        mt={1}
                        onClick={() =>
                          router.push(`/profile/network/${user.slug}`)
                        }
                        cursor="pointer"
                      >
                        <AvatarGroup color="blackAlpha.500" size="sm" max={3}>
                          {mutual_connections?.map?.((u, uInd) => (
                            <Avatar
                              as={Link}
                              href={'/p/' + u?.slug}
                              key={uInd}
                              width="18px"
                              height="18px"
                              src={getUserProfilePic(u)}
                              name={getUserFullName(u)}
                              border="1px solid #fff"
                            />
                          ))}
                        </AvatarGroup>
                        <Text fontSize="12px" color="#868585" ms={1}>
                          {mutual_connection_count > 3
                            ? `+${mutual_connection_count - 3}`
                            : ''}{' '}
                          {t('profileNetwork.sharedConnections') || "shared connections"}
                        </Text>
                      </HStack>
                    </>
                  ) : (
                    ''
                  )}
                  {children}
                </Stack>
            <Stack
              {...(props?.flexWrap ? { w: '100%' } : {})}
              h="100%"
              mt={1}
              justifyContent="center"
              alignItems={'flex-start'}
              minH={isSearchCard ? '50px' : 'auto'}
              // alignItems="start"
            >
              {actions}
            </Stack>
              </HStack>
            </HStack>

          </>
        )}
      </>
    );
  }
);
