/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@mybridge/ui';
import { useContext } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { TeamsContext } from '../context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

export const TeamRemoveOrganizerModal = ({ onClose, title }) => {
  const { t } = useTranslator();
  const router = useRouter();
  const {
    removeTeamOrganizer,
    refetchTeamDetails,
    team,
    selectedUser,
    teamDetails,
    removeTeamOrganizerLoading,
    removeOrganizerDisc,
  } = useContext(TeamsContext);
  const { userProfileInfo } = useSelector(state => state?.userProfile);
  console.log('selectedUser', teamDetails);
  
  const handleRemoveOrganizer = () => {
    removeTeamOrganizer?.(selectedUser)
      .then(() => {
        refetchTeamDetails?.();
        
        // Check if user is removing themselves as an organizer
        if (userProfileInfo?.id === selectedUser?.id) {
          // Redirect to team details page
          router.push(`/teams/${teamDetails?.slug}`);
        }
      });
  };
  
  return (
    <Modal {...removeOrganizerDisc}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text width={"96%"}>{t('profile.remove') || "Remove"} {getUserFullName(selectedUser)} {t('teamsPage.asOrganizer') || "as Organizer?"}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text display="inline">
              {/* {getUserFullName(selectedUser)} will have access to {teamDetails?.name??""} and any team resources within, including all files and
              conversations. */}
              {getUserFullName(selectedUser)} {t('teamsPage.removedAsOrganizer') || "will be removed as an organizer from"} {teamDetails?.name??""}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant="secondary"
              onClick={(e) => removeOrganizerDisc?.onClose?.()}
            >
              {t('buttons.cancel') || "Cancel"}
            </Button>
            <Button
              isLoading={removeTeamOrganizerLoading}
              variant="primary"
              colorScheme="danger"
              onClick={handleRemoveOrganizer}
            >
              {t('teamsPage.removeorganizer') || "Remove Organizer"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
